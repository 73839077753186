import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'
import { graphql } from 'gatsby'
import tw from 'tailwind.macro'
import Img from 'gatsby-image'
import config from '../../config/website'
import { colors, screens } from '../../tailwind'
import SVG from '../components/SVG'

// Components
import Menu from '../components/Menu'
import Layout from '../components/Layout'
import Footer from '../components/Footer'

// Views
import AboutView from '../views/About'
import ResumeView from '../views/Resume'

const Container = styled.div`
  margin-bottom: 20rem;
`

const Section = styled.div`
  position: relative;
  margin: 0 0 8rem;

  @media (min-width: ${screens.lg}) {
    margin: 4rem 0 12rem;
  }
`

const ImgContainer = styled.div`
  position: absolute;
  top: 8rem;
  left: 0;
  width: 175px;
  z-index: 0;

  @media (min-width: ${screens.lg}) {
    top: 18rem;
    width: 375px;
  }
`

const Content = styled.div`
  ${tw`m-auto px-8 md:px-16`}

  @media (min-width: ${screens.lg}) {
    max-width: 45rem;
    margin-left: 35%;
  }
`

const theme = {
  menu: colors.black,
  menuHover: colors['am-green-ally'],
  accent: colors['am-blue'],
  ally: colors['am-blue-ally'],
}

const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix
const homeURL = `${config.siteUrl}${realPrefix}`
const image = `${homeURL}/about.jpg`

const AboutPage = ({ data }) => (
  <ThemeProvider theme={theme}>
    <Layout>
      <Helmet>
        <meta property="og:image" content={image} />
      </Helmet>
      <Menu />
      <Container>
        <Section style={{ paddingTop: '10rem' }}>
          <SVG
            icon="circle"
            width="30%"
            fill={colors['am-yellow']}
            left="80%"
            top="-10%"
            mobile={{ top: '-2%', left: '80%' }}
          />
          <SVG
            icon="scaleneTriangle"
            width="55%"
            fill={colors['am-blue']}
            left="-15%"
            top="5%"
            rotate="70deg"
            mobile={{ top: '7%', left: '-15%', width: '75%' }}
          />
          <SVG
            icon="triangle"
            width="25%"
            fill={colors['am-orange']}
            left="80%"
            top="90%"
            mobile={{ top: '95%', left: '75%', width: '35%' }}
          />
          <SVG
            icon="rectangle"
            width="35%"
            fill={colors['am-green']}
            left="-15%"
            top="150%"
            mobile={{ top: '150%', left: '-18%', width: '35%' }}
            rotate="90deg"
          />
          <ImgContainer>
            <Img fluid={data.Alex.childImageSharp.fluid} />
          </ImgContainer>
          <Content>
            <AboutView />
          </Content>
        </Section>
        <Section>
          <Content>
            <ResumeView />
          </Content>
        </Section>
      </Container>
      <Footer />
    </Layout>
  </ThemeProvider>
)

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const pageQuery = graphql`
  query {
    Alex: file(relativePath: { eq: "about/alex.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
