import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screens } from '../../tailwind'

// Components
import TagList from '../components/TagList'

// Elements
import { StyledLinkOut } from '../elements/Links'
import { Title } from '../elements/Titles'

const Company = styled.h2`
  ${tw`inline-block mb-4 xl:text-3xl text-black font-bold`};
`

const DateLocation = styled.div`
  ${tw`m-0 mt-2 mb-2 text-lg md:text-2xl font-light`};

  @media (min-width: ${screens.md}) {
    ${tw`inline-block ml-2`};

    &::before {
      content: '|';
      ${tw`mr-2`};
    }
  }
`

const Section = styled.div`
  margin-bottom: 3rem;
`

const Role = styled.h3`
  ${tw`inline-block m-0 mb-2 text-black font-bold`};

  &:last-of-type {
    ${tw`mb-3`};
  }
`

const Year = styled.p`
  ${tw`inline-block m-0 ml-2 mb-2 text-grey-darker`};
`

const ResumeView = () => (
  <>
    <Title>Resume</Title>
    <Company>
      IBM Design
      <DateLocation>San Francisco, CA | 2018-2020</DateLocation>
    </Company>
    <Section>
      <Role>UX Designer 2</Role>
      <Year>(2019 - 2020)</Year>
      <br />
      <Role>UX Designer 1</Role>
      <Year>(2018 - 2019)</Year>
      <br />
      <TagList tags={['Research', 'UI', 'UX', 'Workshop Facilitation', 'Event Design']} />
    </Section>

    <Company>
      IBM Design
      <DateLocation>Austin, TX | 2017</DateLocation>
    </Company>
    <Section>
      <Role>UX Intern</Role>
      <br />
      <TagList tags={['Research', 'UX']} />
    </Section>

    <Company>
      iFactory
      <DateLocation>Boston, MA | 2016</DateLocation>
    </Company>
    <Section>
      <Role>Visual Design Co Op</Role>
      <br />
      <TagList tags={['UI', 'Web Design']} />
    </Section>

    <Company>
      Scout
      <DateLocation>Boston, MA | 2016-2017</DateLocation>
    </Company>
    <Section>
      <Role>Design Lead</Role>
      <Year>(2017)</Year>
      <br />
      <Role>Designer</Role>
      <Year>(2016 - 2017)</Year>
      <br />
      <TagList tags={['Project Management', 'Research', 'UI', 'UX']} />
    </Section>
    <StyledLinkOut href="/alex-morris-resume.pdf" target="_blank" rel="noopener noreferrer">
      View full resume
    </StyledLinkOut>
  </>
)

export default ResumeView
