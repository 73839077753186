import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '../../tailwind'

import { Title } from '../elements/Titles'
import { StyledLinkOut } from '../elements/Links'

const AboutHero = styled.div`
  ${tw`mt-8 pt-32`};
`

const AboutSub = styled(Title)`
  ${tw`text-black pt-12 lg:pt-0 font-black mb-6 text-3xl`};
`

const AboutDesc = styled.section`
  ${tw`text-black text-xl`};
  line-height: 2rem;
`

const ConnectList = styled.ul`
  ${tw`list-reset`};

  li {
    ${tw`mb-6`};
  }

  a {
    ${tw`text-black font-bold`};
  }

  svg {
    ${tw`mr-4`};
    color: ${colors['am-blue-ally']};
  }
`

const connect = [
  { icon: ['fas', 'envelope'], text: 'alexmorristx@gmail.com', link: 'mailto://alexmorristx@gmail.com' },
  { icon: ['fab', 'linkedin-in'], text: 'in/alexbmorris', link: 'https://linkedin.com/in/alexbmorris' },
  { icon: ['fab', 'medium-m'], text: '@alexbmorris', link: 'https://medium.com/@alexbmorris' },
]

const AboutView = () => (
  <>
    <AboutHero>
      <AboutSub>Hey, nice to meet you.</AboutSub>
    </AboutHero>
    <AboutDesc>
      <p>I’m a San Francisco based software designer who gets joy from creating culture everywhere I go.</p>
      <p>
        From 2018-2020 I was a UX designer at IBM Design improving the experience of tools in Data & AI. In the office I
        earned the name “Queen of Culture” by hosting murder mystery parties and making desk-side mini waffles for
        passersby.
      </p>
      <p>
        Before IBM, I was in Boston as a web designer at IFactory elevating the sites of universities and healthcare
        companies.
      </p>
      <p>
        In my free time, I practice and perform improvisational theater. I bring this passion{' '}
        <StyledLinkOut
          href="https://medium.com/design-ibm/what-yes-and-really-means-in-the-workplace-61e32d44325d"
          target="_blank"
          rel="noopener noreferrer"
        >
          into the workplace
        </StyledLinkOut>{' '}
        by mixing it with design thinking workshops and team building exercises. I also volunteer with both TedxSF,
        coordinating event logistics, and Covia Senior homes, visiting the elderly in SF.
      </p>
      <p>Connect with me:</p>
      <ConnectList>
        {connect.map(({ link, icon, text }) => (
          <li key={text}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={icon} />
              {text}
            </a>
          </li>
        ))}
      </ConnectList>
    </AboutDesc>
  </>
)

export default AboutView
